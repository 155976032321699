.container{
    padding:0 1rem;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-flow: column;
}

.resultContainer{
    height: 20vh;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 11px;
    font-size:18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4rem;
    padding:0 1rem;
    width: 100%;
    flex: 1;

}
.resultInner{
    flex-direction: column;
    padding:45px 0 20px;
    align-items: center;

}
@media screen and (max-width:767px) {
    .resultInner{
        padding:16px 0 25px
    }
}
@media screen and (max-width:767px) {
    .resultContainer{
        margin-bottom:1rem
    }
}
.party{
   width: 100px; 
   height: auto;
}
@media screen and (max-width:767px) {
    .party{
        width: 50px; 
    }
}
.label{
    font-size: 20pxrem;
    font-weight: 700;
    color:#000
}
.resultInner p{
    font-size: 1rem;
}
.text{
    display: flex;
    align-items: flex-start;
    column-gap: 2px;

}
.desc{
    font-weight: 400;
    text-align: left;
    font-size:12px
}
@media screen and (min-width:767px) {
    .desc{
        margin: 0 75px; 
    }
}
.button{
    margin-top: auto;
    width: 100%;
}
@media screen and (min-width:767px) {
    .button{
        width: 50%; 
    }
}
@media screen and (max-width:1199px){
    .qr{
        display: none;
    }
}
@media screen and (min-width:1200px){
    .button{
        display: none;
    }
}
.qr img{
    height: 100px;
    width: 100px;
}

