@import "./styles/antd.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');



body {
  margin: 0;
  font-family:'DM Sans',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F2F2;
  font-size: 16px;
  color: #173627;
}


@media screen and (max-width:767px) {
  body{  
     font-size:15px
  }
  
}

.flex-center-f{
  display: flex;
  justify-content: center;
  width: 100%;
}

