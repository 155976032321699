.container{
    padding:0 1rem;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-flow: column;
}
.steps{
    position: absolute;
    top: -2.5rem;
    left: auto;
    right: auto

}
.customProgress{
    font-size: 2.5rem;
    line-height: 4rem;
}
.questionContainer{
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 11px;
    font-size:18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    padding:0 1rem;
    width: 100%;
}
@media screen and (max-width:767px){
    .questionContainer{
        padding-top: 8vh;
        align-items:flex-start
    }
}
.birthdayContainer{
    flex:1;
    background-color: white;
    border-radius: 11px;
    width: 100%;
    margin-bottom: 4rem;
    padding: 1rem;
}
@media screen and (max-width:767px) {
    .birthdayContainer{  
        margin-bottom: 1rem;
    }
    
}
.content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.birthday{
    align-content: space-between;
}
.birthdate{
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 10px;
}
.dateSelector{
    width: 100%;
    border-radius: 15px;
    border: 1px solid #e6e6e6;
    height: 48px;
    padding: 16px 10px 4px 0;
    font-weight: 700;
}