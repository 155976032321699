.floatLabel {
    position: relative;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  
  .label {
    font-size: .75rem;
    font-weight: 600;
    position: absolute;
    pointer-events: none;
    left: 12px;
    color:rgba(23, 54, 39, 0.5);
    transition: 0.2s ease all;
  }
  
  .labelFloat {
    top: 6px;
    font-size: 0.75rem;
  }
  