.container{
    padding:0 1rem;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-flow: column;
}
.steps{
    position: absolute;
    top: -2.5rem;
    left: auto;
    right: auto

}
.customProgress{
    font-size: 2.5rem;
    line-height: 4rem;
}
.questionContainer{
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 11px;
    font-size:18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    padding:0 1rem;
    width: 100%;
}
@media screen and (max-width:767px){
    .questionContainer{
        padding-top: 8vh;
        align-items:flex-start
    }
}
.birthdayContainer{
    flex:1;
    border-radius: 11px;
    width: 100%;
    margin-bottom: 4rem;

}
@media screen and (max-width:767px) {
    .birthdayContainer{  
        margin-bottom: 1rem;
    }
    
}
.content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.birthdate{
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 10px;
}
.dateSelector{
    width: 100%;
    border-radius: 15px;
    border: 1px solid #e6e6e6;
    height: 48px;
    padding: 16px 10px 4px 0;
    font-weight: 700;
}
.emojiBox{
    border: 1px solid #E6E6E6;
    border-radius: 27px;
    width: 100%;
    text-align:center;
    padding-top:10px;
    padding-bottom: 10px;
    background-color: #fff;

}

@media screen and (max-width:767px) {
    .emojiBox{  
        text-align:start;
    }
    
}
.cardBox{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius:10px;
    column-gap: 10px;
    font-weight: 500;
    font-size:18px;
    height: 100%;
}
.selectedItem{
    border: solid 1px #745ebb1b;
}
@media screen and (max-width:767px) {
    .cardBox{  
        font-size: 15px;
    }
    
}
.icons{
    height: 60px;
    width: 60px;
    padding:10px;


}
.iconContainer{
    background-color: #745ebb1b;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-bottom:10px;
    object-fit: cover;
    

}
.cardBoxTitle{
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center; 
}