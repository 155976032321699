.header {
  height: 30vh;
  background-color: #54D9D5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  z-index: 1;
}

.AppLogo {
  pointer-events: none;
  margin-top:7vh
}
@media screen and (max-width:767px) {
  .AppLogo {
    width: 50%;
    margin-top:7vh
  }
  
}
.content{
  max-width: 770px;
  margin:-7vh auto 0;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main{
  height: 100svh;
  display: flex;
  flex-direction: column;
}
.spinnerContainer{
  width: 100%;
  background-color: white;
  z-index: 2;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  border-radius: 11px;
  flex-direction: column;
  margin:0 1rem
}
@media screen and (max-width:767px) {
  .spinnerContainer{
    margin-bottom: 1rem;
  }
  
}