.primaryButton{
    background-color: #735EBB;
    border-radius: 30px;
    color:#fff;
    width: 100%;
    padding:.75rem 0;
    height: fit-content;
    font-weight:700 ;

}
.primaryButton:hover,.primaryButton:focus{
    background-color: #735EBB;
    color:#fff;
    border-color: #5f44ba;

}